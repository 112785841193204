@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');

body{
  background: #f3f3f3;
  font-family: "Open Sans", sans-serif;
}

html{
  font-size: 100%;
}

#loginform{
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  margin: 100px auto;
  background-color: #FFFFFF;
  border: 1px solid rgba(173,173,173,0.3);
  border-radius: 15px;
  -webkit-box-shadow: 3px 2px 10px 0px rgba(173,173,173,1);
  -moz-box-shadow: 3px 2px 10px 0px rgba(173,173,173,1);
  box-shadow: 3px 2px 10px 0px rgba(173,173,173,1);
}

#headerTitulo{
  text-align: center;
  font-family: 'open sans', sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.row{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
  
}

.row input{
  width: 80%;
  box-sizing: border-box;
	border: none;
  font-size: 1.3rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus{
   box-shadow: inset 0px -1px 0px 0px rgba(44,38,252,1);
   outline: none;
}

.row input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
  font-size: 15px;
  color: #bbbbbbe6;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder{
  opacity: 0.5;
}

.row label{
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: rgba(143, 143, 143, 0.9);
  font-size: 17px;
}

.row button{
  border-radius: 25px;
  width: 80%;
  height: 50px;
  font-size: 1.3rem;
  color: white;
  font-weight: 500;
  background: #002561;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}

.row button:hover{
  opacity: 0.8;
}

#button{
  padding-bottom: 1.5rem;
}
#login-google,
#login-linkedin{
    padding: 10px 35px;
    border: 1px solid #000;
    border-radius: 50px;
    display: flex;
}
#login-google{
    margin-right: 10px;
}
#login-linkedin{
    margin-left: 10px;
}
#socialGrupo{
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}
#loginSocial {
   text-align: center;
   padding-top: 0.5rem;
   margin-top: 1.5rem;
}
.icone-google,
.icone-linkedin{
    padding-left: 5px;
}
.css-13cymwt-control{
  border-color: #C0C0C0 !important;
  border-radius: 0.375rem !important;
  background-color: transparent !important;
  padding: 0px 10px !important;
  font-size: 0.875rem !important;
}
.css-t3ipsp-control:active{
  padding: 0px 10px;
  background: #f3f3f3;
}
.css-1jqq78o-placeholder {
  color: #9ca3af !important;
  margin-left: -7px !important;
}
.css-hlgwow{
  padding: 9px 10px 8px !important;
  font-size: 14px;
  font-family: 'Work Sans';
}
.titulo-login{
  color:#414141;
  font-size:30px;
  font-weight: 425;
}

.btn-submit{
  background-color: #1346ED;
}

.btn-login-linkedin{
  background-color: #006dba;
}

.btn-login-google{
  background-color: #ffffff;
}

.text-sso-option{color:#c0c0c0;font-size: 12px;font-weight: 425;}

.text-link-gm{color:#1346ED;font-weight: 700;}
.lembrar-senha-check{color:#c0c0c0;font-weight: 425;}
.text-paragraph{font-size:12px;font-weight: 425;line-height: 16.44px;}
.titulo-cadastro{font-weight: 500;line-height: 41px; font-size: 30px;color: #414141;}
.text-terms{font-weight: 700;color: #c0c0c0;}
.link-terms{font-weight: 700;color: #1346ED;}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.link-voltar{
  background-color: #D9D9D9; color:#000; padding: 10px; padding-top:12px;font-size: 14px;
}

.icon-link-voltar{margin-top: 0.1rem;}

.bg-sidebar{background-color: #1346ED;color:#ffffff}
.lista-menu{max-width: 195px; font-size: 12px; line-height: 17px;font-weight: 400;}
.lista-menu li{font-size: 13px !important; line-height: 16px;}
.lista-menu li a:hover{font-weight: 700;}
.text-menu-avatar{font-weight: 700; font-size: 22px; line-height: 24px; max-width: 100%; margin-top: 20px;}
.menu-icon{width: 15px;height: 14px;}
.cards-empresas{margin-top:55px;margin-bottom:34px;background-color: #ffffff;height:133px}
.cards-empresas h1{font-size: 12px;font-weight: 700; line-height: 12px;}
.cards-empresas .card{background-color: #f3f3f3;width: 189px;height: 61px;}
.cards-empresas .card .nome-empresa{font-size: 12px; line-height: 12px; font-weight: 500;}
.cards-empresas .card p{font-size: 10px; line-height: 10px; font-weight: 400;}
.peer:placeholder-shown ~ .peer-placeholder-shown\:scale-100{background-color: #f3f3f3;}
.dark\:text-gray-400 {background-color: #f3f3f3;}
#adicionar-empresa .peer:placeholder-shown ~ .peer-placeholder-shown\:scale-100{background-color: #ffffff;}
#adicionar-empresa .dark\:text-gray-400 {background-color: #ffffff;}
.text-blue-gm{color:#1346ED}

@media (max-width: 576px) {
  .w-full-mobile{
    min-width: 100%!important;
  }
}
.select-filtro-compv{
  background-color: #EFEFEF !important;
  font-size: 12px;
  font-weight: 400;
  padding: .8rem .625rem .8rem 2.3rem;
  width: 100%;
  height: 44px;
  border-radius: 50px;
  color: #656565 !important;
}

.select-filtro-histcomp{
  background-color: #EFEFEF !important;
  font-size: 14px;
  font-weight: 400;
  padding: .850rem .625rem .850rem .625rem;
  width: 100%;
  border: 1px solid #B9B9B9 !important;
  border-radius: 50px;
  color: #656565 !important;
}
.input-filtro-histcomp{
  background-color: #EFEFEF !important;
  font-size: 14px;
  font-weight: 400;
  padding: .850rem .625rem .850rem .625rem;
  width: 100%;
  border: 1px solid #B9B9B9 !important;
  border-radius: 50px;
  color: #656565 !important;
  max-height: 46px;
}
.input-filtro-histcomp::placeholder{
  color: #656565;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
}
/* Filtro Slider */
.horizontal-slider {
  width: 100%;
  height: 6px;
  background-color: #B9B9B9;
  margin-top: 20px;
}
.horizontal-slider .example-thumb {
  width: 23px;
  height: 23px;
  cursor: pointer;
  background-color: #112587;
  border-radius: 50px;
  border: 2px solid #112587;
  top: -7px;
}

.example-track {
  background: #ddd;
  height: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.example-track-between {
  background: #0b3d91;
}